import React from 'react';

const PagePurchases = () => {
    return (
        <div>
            PagePurchases
        </div>
    );
};

export default PagePurchases;
